<template lang="pug">
.row
  .col
    slot(name="heading")
  .col-auto
    slot(name="link")
  .col-12
    .theme-slider-body
      .theme-slider-body-swiper(v-swiper:swiperRefThemes="swiperOptionsThemes")
        .swiper-wrapper
          .swiper-slide(v-for="slide in slidesData" :key="slide.name")
            slot(name="slide-component" v-bind="slide")
      .swiper-button-next(tabindex="0" role="button" :id="`swiper-button-next-${sliderId}`")
      .swiper-right-gradient
      .swiper-button-prev(tabindex="0" role="button" :id="`swiper-button-prev-${sliderId}`")
      .swiper-left-gradient
      .swiper-pagination(v-if="pagination" :id="`swiper-pagination-${sliderId}`")
</template>

<script>
  import { Swiper as SwiperClass, Navigation, Autoplay, Pagination } from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import { nanoid } from 'nanoid';

  SwiperClass.use([Navigation, Autoplay, Pagination]);
  const SwiperComponent = getAwesomeSwiper(SwiperClass);

  const { directive } = getAwesomeSwiper(SwiperClass);

  export default {
    name: 'SwiperBlock',
    components: { SwiperComponent },
    directives: {
      swiper: directive,
    },
    props: {
      slidesPerView: { default: 2.5 },
      slidesPerViewTabletLg: { default: 2.5 },
      slidesPerViewTabletSm: { default: 1.75 },
      slidesPerViewMobile: { default: 1.75 },
      slidesData: {
        type: Array,
        required: true,
      },
      pagination: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        sliderId: nanoid(8),
      };
    },
    computed: {
      swiperOptionsThemes() {
        return {
          allowTouchMove: false,
          slidesPerView: this.slidesPerViewMobile,
          spaceBetween: 24,
          autoHeight: true,
          navigation: {
            nextEl: `#swiper-button-next-${this.sliderId}`,
            prevEl: `#swiper-button-prev-${this.sliderId}`,
          },
          pagination: {
            el: `#swiper-pagination-${this.sliderId}`,
            clickable: true,
          },
          breakpoints: {
            768: {
              slidesPerView: this.slidesPerViewTabletSm,
              spaceBetween: 24,
            },
            1200: {
              slidesPerView: this.slidesPerViewTabletLg,
              spaceBetween: 24,
            },
            1400: {
              slidesPerView: this.slidesPerView,
              spaceBetween: 24,
            },
          },
        };
      },
    },
  };
</script>
